<template>
  <v-app>
    <v-col cols="11" class="mx-auto">
      <div class="jumbotron p-5">
        <h5 class="display-1">Vet Claims within this batch</h5>
        <p>Mark each claims as complete</p>
      </div>
      <div v-if="batch">
        <h6 class="py-4">
          Batch name:
          <strong>{{batch.name}}</strong>
        </h6>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>S/No</th>
                <th>Patient Name</th>
                <th>Date of Birth</th>
                <th>Identification Number</th>
                <th>Date Created</th>
                <th></th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody v-for="(claim, index) in claims" :key="claim.id">
              <tr>
                <td>{{ index + 1}}</td>
                <td>{{ claim.first_name }} {{ claim.last_name }}</td>
                <td>{{ claim.date_of_birth }}</td>
                <td>{{ claim.unique_id }}</td>
                <td>{{ claim.date_created }}</td>
                <td>
                  <v-btn
                    small
                    color="green darken-4 white--text"
                    class="font-weight-bold"
                    :to="`/claims-processing/vetting/batches/${batch.id}/claims/${claim.id}`"
                  >Check</v-btn>
                </td>
                <td v-if="claim.completed">
                  <v-icon color="green" class="d-inline mr-2" small>check_circle</v-icon>Complete
                </td>
                <td v-else>
                  <v-icon color="red" class="d-inline mr-2" small>cancel</v-icon>Incomplete
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-col>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "vet-claim-list",
  computed: {
    ...mapGetters({ batches: "claimModule/batches" }),
    claims() {
      const batch = this.batches.find(
        (batch) => batch.id === Number(this.$route.params.batch_id)
      );
      return batch.claims;
    },
    batch() {
      const batch = this.batches.find(
        (batch) => batch.id === Number(this.$route.params.batch_id)
      );
      return batch;
    },
  },
};
</script>

<style scoped>
.position {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.bgColor {
  background-color: #eee;
}
a:hover {
  text-decoration: none;
}
</style>
